@import '@standby/common-ui/color.scss';

.wrap{
    padding: 0 60px;
    padding-bottom: 60px;
    .pageTitle{
        border-bottom: 1px solid $neutral700;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 16px;
        padding-top: 36px;
        .title{
            color: $primary800;
            font-size: 22px;
            font-weight: 700;
            line-height: 100%;
        }
        .btns{
            font-size: 14px;
            font-weight: 400;
            line-height: 100%;
            .lineBtn{
                background-color: transparent;
                color: $neutral700;
                text-decoration-line: underline;
            }
            .arrowBtn{
                background-color: transparent;
                color: $main500;
                padding-right: 14px;
                background-image: url('/images/arrowRight_main500.svg');
                background-repeat: no-repeat;
                background-position: right top 3px;
            }
            button + button{
                margin-left: 34px;
                position: relative;
                &:before{
                    position: absolute;
                    content: '';
                    display: inline-block;
                    width: 2px;
                    height: 15px;
                    background-color: $neutral300;
                    left: -16px;
                    top: 2px;
                }
            }
        }
    }
    .pageContent{
        position: relative;
        .inputRows{
            display: flex;
            margin-top: 24px;
            .rowTitle{
                color: $neutral800;
                font-size: 16px;
                font-weight: 500;
                line-height: 100%;
                width: 190px;
                position: relative;
                top: 13px;
                &.required::after{
                    content: '*';
                    color:#ED2B00;
                }
            }
            .rowContent{
                .radioList{
                    li:not(:first-child){
                        margin-top: 5px;
                    }
                }
                .seconRow{
                    position: relative;
                    &:not(:first-child) {
                        margin-top: 12px;
                    }
                    .plusInput{
                        position: absolute;
                        right: 0;
                        top: 3px;
                    }
                    .minusInput{
                        position: absolute;
                        right: 0;
                        bottom: 3px;
                    }
                }
                .personInChargeCustomer{
                    .inputRows{
                        &:nth-child(1){
                            margin-top: 0;
                        }
                        .rowTitle{
                            width: 93px;
                            font-weight: 400;
                        }
                    }
                    .btns{
                        display: flex;
                        justify-content: flex-end;
                        margin-top: 12px;
                        button{
                            width: 107px;
                            margin-left: 8px;
                        }
                    }
                    li + li{
                        margin-top: 24px;
                        padding-top: 24px;
                        border-top: 1px solid $neutral300;
                    }
                }
            }
        }
        .fixedSave{
            position: absolute;
            bottom: 0px;
            right: 0;
            width: 88px;
            height: 88px;
            background-color: $neutral100;
            border: 1px solid $neutral300;
            box-shadow: 0px 1px 4px 2px rgba(153, 153, 153, 0.25);
            border-radius: 50%;
            color: $main500;
            font-size: 13px;
            font-weight: 400;
            &:disabled{
                color: $neutral400;
                border: 1px solid $neutral300;
                background-color: $neutral200;
                box-shadow: 0px 1px 4px 2px rgba(153, 153, 153, 0.25);
            }
        }
        .save{
            margin-top: 24px;
            padding-top: 36px;
            border-top: 1px solid $neutral300;
            text-align: center;
            button{
                display: inline-block;
                width: 576px;
            }
        }
    }
}