@import '@standby/common-ui/color.scss';
.modalContent{
    width: 676px;
    padding: 36px 50px;
    .sendList{
        display: flex;
        > .title{
            color: $neutral800;
            font-size: 16px;
            font-weight: 400;
            line-height: 100%;
            width: 93px;
        }
        .checkboxList{
            &:not(:first-child){
                margin-top: 12px;
            }
            li{
                &:not(:first-child){
                    margin-top: 8px;
                }
            }
        }
    }
    .btnWrap {
        margin-top: 24px;
    }
    .mainBtn{
        width: 410px;
        height: 48px;
        background-color: #fff;
        border: 1px solid $main400;
        border-radius: 4px;
        &:disabled{
            border-color: $neutral300;
            background-color: $neutral200;
            span{
                color: $neutral400;
                background-image: url('/images/sendTalkIcon_disabled.svg');
            }
        }
        span{
            color: $main500;
            font-size: 14px;
            background-image: url('/images/sendTalkIcon.svg');
            background-repeat: no-repeat;
            background-position: left center;
            padding-left: 20px;
        }
    }
}