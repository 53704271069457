.businessName{
    display: flex;
    align-items: center;
    .historyBtn{
        width: 16px;
        height: 16px;
        background-image: url('/images/history_Icon.svg');
        background-color: transparent;
        margin-left: 5px;
    }
    .companyHome{
        width: 16px;
        height: 16px;
        background-image: url('/images/companyHomeIcon.svg');
        background-color: transparent;
        margin-left: 8px;
    }
}