@import '@standby/common-ui/color.scss';
.findPassword{
    width: 100%;
    height: 100vh;
    background-color: $primary900;
    padding-top: 105px;
    .findPasswordForm{
        width: 620px;
        background-color: $neutral100;
        border-radius: 8px;
        margin:0 auto;
        padding: 70px 80px 110px 80px;
        min-height: 580px;
        .logo{
            text-align: center;
            margin-bottom: 38px;
        }
        .explain{
            text-align: center;
            .title{
                color: $neutral900;
                font-size: 16px;
                font-weight: 400;
            }
            .content{
                font-weight: 400;
                font-size: 14px;
                color: $neutral600;
                margin-top: 3px;
                margin-bottom: 33px;
            }
        }
        .label{
            font-size: 15px;
            font-weight: 400;
            color: $neutral900;
            margin-bottom: 5px;
        }
    }
    .btnArea{
        margin-top: 36px;
        .findBtn{
            width: 100%;
        }
    }
    .explainEmailSend{
        color: $neutral600;
        font-size: 14px;
        padding-left: 10px;
        position: relative;
        font-weight: 400;
        margin-top: 8px;
        &:before{
            content: '*';
            position: absolute;
            display: inline-block;
            top: 3px;
            left: 0;
        }
    }
    .resend{
        padding-right: 18px;
        background-image: url('/images/resendIcon.svg');
        background-position: right top 6px;
        background-repeat: no-repeat;
    }
    .back{
        text-align: center;
        margin-top: 3px;
        button{
            color: $neutral500;
            background-color: transparent;
            text-decoration: underline;
            font-weight: 400;
        }
    }
}