@import '@standby/common-ui/color.scss';

.changeEmail {
    width: 100%;
    height: 100vh;
    background-color: $primary900;
    padding-top: 105px;
    .changeEmailForm{
        width: 620px;
        background-color: $neutral100;
        border-radius: 8px;
        margin:0 auto;
        padding: 70px 80px 110px 80px;
        min-height: 580px;
        .logo{
            text-align: center;
            margin-bottom: 38px;
        }
        .explain{
            text-align: center;
            .title{
                color: $neutral900;
                font-size: 16px;
                font-weight: 400;
            }
        }
        .label{
            font-size: 15px;
            font-weight: 400;
            color: $neutral900;
            margin-bottom: 5px;
        }
    }
    .btnArea{
        margin-top: 36px;
        .findBtn{
            width: 100%;
        }
    }
}