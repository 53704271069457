@import '@standby/common-ui/color.scss';

.pageNav{
    padding-top: 20px;
    span{
        font-size: 16px;
        color: $neutral600;
        font-weight: 400;
        padding-right: 22px;
        background-image: url('/images/pageNavIcon.svg');
        background-repeat: no-repeat;
        background-position: right 5px top 6px;
        &:nth-last-child(1){
            padding-right: 0;
            background-image: none;
            color: $neutral900;
            font-weight: 500;
        }
    }
}