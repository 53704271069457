@import '@standby/common-ui/color.scss';

.modal{
    padding: 36px 50px;
    width: 720px;
    .companyName{
        font-size: 16px;
        font-weight: 600;
        color : $neutral800
    }
    .listTitle{
        color : $neutral800;
        font-size: 16px;
        font-weight: 400;
        margin-top: 10px;
    }
    .targetList{
        margin-top: 10px;
        margin-bottom: 16px;
        li{
            margin-top: 8px;
            &:nth-child(1){
                margin-top: 0;
            }
        }
    }
    .contentTitle{
        color: $neutral800;
        font-size: 16px;
        font-weight: 400;
        margin-top: 22px;
    }
    .textarea{
        width: 380px;
        height: 150px;
        border: 1px solid $neutral400;
        border-radius: 4px;
        margin-top: 10px;
        color: $neutral700;
        font-size: 13px;
        line-height: 16.9px;
        padding: 10px 12px;
        &::placeholder{
            color: $neutral500;
        }
    }
    .btnArea{
        margin-top: 22px;
        text-align: center;
    }
    .sendBtn{
        width: 330px;
        height: 40px;
        background-color: #fff;
        border: 1px solid $main400;
        border-radius: 4px;
        &:disabled{
            border-color: $neutral300;
            background-color: $neutral200;
            span{
                color: $neutral400;
                background-image: url('/images/sendTalkIcon_disabled.svg');
            }
        }
        span{
            color: $main500;
            font-size: 14px;
            background-image: url('/images/sendTalkIcon.svg');
            background-repeat: no-repeat;
            background-position: left center;
            padding-left: 20px;
        }
    }
}