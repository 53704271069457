@import '@standby/common-ui/color.scss';
.hint{
    .title{
        button{
            color: $neutral600;
            background-color: transparent;
            font-size: 14px;
            text-decoration: underline;
            font-weight: 400;
            background-image: url('/images/openHintIcon.svg');
            background-position: right top 5px;
            background-repeat: no-repeat;
            padding-right: 16px;
        }
        &.opened{
            button{
                color: $neutral800;
                background-image: url('/images/closeHintIcon.svg');
            }
        }
    }
    .content{
        margin-top: 2px;
    }
}