@import '@standby/common-ui/color.scss';
.gridContent{
    margin-top: 24px;
    position: relative;
}
.tab{
    display: flex;
    li{
        border: 1px solid $neutral400;
        border-bottom: none;
        font-size: 15px;
        font-weight: 400;
        color: $neutral600;
        padding: 10px 25px 12px 25px;
        border-radius: 4px 4px 0px 0px;
        margin-left: 4px;
        cursor: pointer;
        &:nth-child(1){
            margin-left: 0;
        }
        &.active{
            font-weight: 500;
            color: $main500;
            border-color: $main400;
            background-color: $main000;
            position: relative;
            &:after{
                content: '';
                display: inline-block;
                position: absolute;
                bottom: -2px;
                left: 0;
                right: 0;
                height: 3px;
                background-color: $main000;
                z-index: 100;
            }
        }
    }
}
.grid{
    height: calc(100vh - 180px);
    :global {
        .ag-root-wrapper{
            border: none;
        }
        .ag-header-cell-text{
            font-weight: 500;
        }
        .ag-header{
            background-color: $main000;
            border: 1px solid $main400;
        }
        .ag-body{
            border: 1px solid $neutral400;
            border-top: none;
        }
        .ag-pinned-left-header{
            border-color: $main400;
        }
        .ag-pinned-right-header{
            border-color: $main400;
        }
        .ag-header-row:not(:first-child) .ag-header-cell:not(.ag-header-span-height.ag-header-span-total){
            border-color: transparent;
        }
        .ag-header-cell-resize::after{
            background-color: $main100;
            opacity: 0.5;
        }
        .ag-row{
            border-color: $neutral300;
            background-color: $neutral100;
            color: $neutral800;
        }
        .ag-row-hover{
            .ag-cell{
                background-color: $main000;
            }
        }
        .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell):not(.ag-cell-focus){
            border-right-color: $neutral300;
            border-right-width: 2px;
        }
        .ag-ltr .ag-cell{
            border-right: 1px solid $neutral300;
        }
        .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell):not(.ag-cell-focus){
            border-left-color: $neutral300;
            border-left-width: 2px;
        }
    }
}

.gridPageSize{
    position: absolute;
    left: 0;
    bottom: 9px;
    .title{
        font-size: 15px;
        color: $neutral800;
        margin-right: 8px;
    }
}
.selectedSmall{
    border: 1px solid $neutral400;
    border-radius: 4px;
    height: 28px;
    padding-left: 10px;
    padding-right: 34px;
    color: $neutral800;
    font-size: 14px;
    font-weight: 400;
    appearance: none;
    width: 70px;
    letter-spacing: 1;
    background-image: url('/images/select_mini_icon.svg');
    background-repeat: no-repeat;
    background-position: right 8px center;
}