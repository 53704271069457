@import '@standby/common-ui/color.scss';

.wrap{
    padding: 0 60px;
    padding-bottom: 60px;
    .pageTitle{
        border-bottom: 1px solid $neutral700;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 16px;
        padding-top: 36px;
        .title{
            color: $primary800;
            font-size: 22px;
            font-weight: 700;
            line-height: 100%;
        }
        .btns{
            font-size: 14px;
            font-weight: 400;
            line-height: 100%;
            .lineBtn{
                background-color: transparent;
                color: $neutral700;
                text-decoration-line: underline;
            }
            button + button{
                margin-left: 34px;
                position: relative;
                &:before{
                    position: absolute;
                    content: '';
                    display: inline-block;
                    width: 2px;
                    height: 15px;
                    background-color: $neutral300;
                    left: -16px;
                    top: 2px;
                }
            }
        }
    }
    .detail{
        padding: 24px 0;
        display: flex;
        &:not(:first-child){
            border-top: 1px solid $neutral300;
        }
        &.top{
            display: block;
            position: relative;
            > .top {
                display: flex;
                align-items: center;
                > .companyName{
                    color: $neutral800;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 100%;
                    letter-spacing: -0.18px;
                    width: 260px;
                }
                > .status{
                    color: $neutral800;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 100%;
                    letter-spacing: -0.64px;
                }
            }
            > .arrowBtn{
                position: absolute;
                right: 0;
                top: 0;
            }
            > .representatives{
                display: flex;
                color: $neutral800;
                font-size: 16px;
                font-weight: 400;
                line-height: 100%;
                margin-top: 16px;
                > .title{
                    margin-right: 12px;
                }
            }
        }
        > .detailTitle{
            color: $neutral800;
            font-size: 16px;
            font-weight: 500;
            line-height: 100%;
            width: 190px;
            position: relative;
            flex-shrink: 0;
        }
        > .detailContent{
            position: relative;
            width: 100%;
            .contentWrap{
                border-bottom: 1px solid $neutral300;
                width: 577px;
                padding: 24px 0;
                &:nth-child(1) {
                    padding-top: 0;
                }
                &:nth-last-child(1) {
                    border-bottom: none;
                }
            }
            .infoTextRow{
                width: 410px;
                display: flex;
                justify-content: flex-end;
                margin-top: 4px;
                .infoText{
                    color: $neutral600;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 130%;
                    letter-spacing: -0.56px;
                    &::before{
                        content: '* ';
                        position: relative;
                        top: 4px;
                    }
                }
            }
            .contentRow{
                display: flex;
                align-items: center;
                &.checkboxRow{
                    align-items: start;
                    &:not(:first-child){
                        margin-top: 16px;
                    }
                }
                &.radioRow{
                    align-items: start;
                }
                &:not(:first-child){
                    margin-top: 12px;
                }
                > .title{
                    color: $neutral800;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 100%;
                    width: 93px;
                }
                > .content{
                    display: flex;
                    align-items: center;
                    .radioList{
                        display: flex;
                        margin-top: -5px;
                        li:not(:first-child) {
                            margin-left: 24px;
                        }
                    }
                    .countText {
                        width: 298px;
                        text-align: right;
                        color: $neutral800;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 100%;
                        padding: 0 14px;
                        display: inline-block;
                    }
                    .inputSubText{
                        color: $neutral800;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 100%;
                        margin-left: 6px;
                    }
                    .checkListWrap {
                        .checkboxList{
                            &:not(:first-child){
                                margin-top: 12px;
                            }
                        }
                    }
                    .checkboxList{
                        li{
                            &:not(:first-child){
                                margin-top: 8px;
                            }
                        }
                    }
                    .loadBtn{
                        color: $main300;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 100%;
                        width: 154px;
                        height: 42px;
                        border-radius: 4px;
                        border: 1px solid $neutral400;
                        background: $neutral100;
                        margin-left: 12px;
                    }
                }
            }
            > .textRow{
                color: $neutral800;
                font-size: 16px;
                font-weight: 400;
                line-height: 100%;
                display: flex;
                align-items: center;
                &:not(:first-child) {
                    margin-top: 20px;
                }
                > .rowTitle{
                    width: 92px;
                    flex-shrink: 0;
                    display: inline-block;
                }
            }
            .btnWrap {
                margin-top: 24px;
            }
        }
    }
    .fixedSave{
        position: absolute;
        bottom: 0px;
        right: 0;
        width: 88px;
        height: 88px;
        background-color: $neutral100;
        border: 1px solid $neutral300;
        box-shadow: 0px 1px 4px 2px rgba(153, 153, 153, 0.25);
        border-radius: 50%;
        color: $main500;
        font-size: 13px;
        font-weight: 400;
        &:disabled{
            color: $neutral400;
            border: 1px solid $neutral300;
            background-color: $neutral200;
            box-shadow: 0px 1px 4px 2px rgba(153, 153, 153, 0.25);
        }
    }
    .mainBtn{
        width: 410px;
        height: 48px;
        background-color: #fff;
        border: 1px solid $main400;
        border-radius: 4px;
        &:disabled{
            border-color: $neutral300;
            background-color: $neutral200;
            span{
                color: $neutral400;
                background-image: url('/images/sendTalkIcon_disabled.svg');
            }
        }
        span{
            color: $main500;
            font-size: 14px;
            background-image: url('/images/sendTalkIcon.svg');
            background-repeat: no-repeat;
            background-position: left center;
            padding-left: 20px;
        }
        &.noneIcon{
            span{
                background-image: none;
            }
            &:disabled span{
                background-image: none;
            }
        }
        &.download{
            span{
                background-image: url('/images/downloadBtnIcon.svg');
            }
            &:disabled span{
                background-image: url('/images/downloadBtnIcon_disabled.svg');
            }
        }
    }
}

.arrowBtn{
    background-color: transparent;
    color: $main500;
    padding-right: 14px;
    background-image: url('/images/arrowRight_neutral700.svg');
    background-repeat: no-repeat;
    background-position: right top 1px;
    font-size: 14px;
    font-weight: 400;
    line-height: 100%;
    &.neutral700{
        color: $neutral700;
    }
}
.copyBtn{
    color: $main200 !important;
    background-image: url('/images/copy_main200.svg') !important;
    line-height: 16px !important;
}
.date{
    input{
        width: 317px;
    }
}