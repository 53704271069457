@import '@standby/common-ui/color.scss';
#header{
    background-color: $primary900;
    height: 60px;
    color: $neutral100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 200;
    a{
        color: $neutral100;
    }
    .content{
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        .logo{
            .logoIcon{
                width: 30px;
                height: 30px;
                background-color: $neutral100;
                border-radius: 4px;
                box-shadow: 0px 1px 4px 2px rgba(13, 61, 111, 0.5);
                margin-right: 10px;
                display: flex;
                justify-content: center;
                img{
                    width: 24px;
                }
            }
            position: absolute;
            left: 36px;
            top: 15px;
            display: flex;
            align-items: center;
            color: $neutral100;
            font-weight: 400;
            font-size: 16px;
        }
        .menuCover{
            display: inline-block;
            height: 100%;
            display: flex;
            align-items: center;
            .mainMenu{
                width: 300px;
                display: flex;
                justify-content: space-around;
                align-items: center;
                font-size: 15px;
                font-weight: 400;
                position: relative;
                height: 100%;
                z-index: 1;
                background-color: $primary900;
                li{
                    width: 120px;
                    text-align: center;
                }
                li.active span{
                    position: relative;
                    font-weight: 400;
                    &:after{
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        height: 2px;
                        display: inline-block;
                        background-color: $neutral100;
                        bottom: -6px;
                    }
                }
            }
        }
        .menuBackgorund{
            position: fixed;
            width: 100%;
            top: 60px;
            height: calc(100% - 60px);
            background: rgba(153, 153, 153, 0);
            visibility: hidden;
            transition: 0.3s;
        }
        .side{
            position: absolute;
            right: 36px;
            top: 18px;
            .buttonIcon{
                background-color: transparent;
                color: $neutral100;
                font-size: 15px;
                font-weight: 400;
                background-repeat: no-repeat;
                background-position: right 0px top 3px;
                padding-right: 20px;
                &.mypage{
                    background-image: url('/images/userIcon.svg');
                    margin-left: 24px;
                }
            }
            .logout{
                width: 16px;
                height: 16px;
                border: none;
                background-color: transparent;
                background-image: url('/images/logoutIcon.svg');
                position: relative;
                top: 3px;
                margin-left: 24px;
            }
        }
    }
}